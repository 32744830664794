import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/Layout"
import SearchEngineOptimization from "../components/SearchEngineOptimization"

const NotFoundPage = ({ intl }) => (
    <Layout pageInfo={{ pageName: "404" }}>
        <SearchEngineOptimization
            lang={intl.locale}
            title={`404: ${intl.formatMessage({ id: "title" })}`}
        />
        <h1>
            <FormattedMessage id="pages.404.title" defaultMessage="NOT FOUND" />
        </h1>
        <p>
            <FormattedMessage
                id="pages.404.text"
                defaultMessage="Looks like this is the end of the road"
            />
        </p>
    </Layout>
)

export default injectIntl(NotFoundPage)
